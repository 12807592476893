<template>
<div>
<van-nav-bar
      class="backBox"
      title="我的车辆"
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />

  <div class="page">

    <main>
      <div class="section_2">
        <div
          class="group_recharge"
          v-for="(item, index) in myCarList"
          :key="item.id"
        >
          <div class="itemBox">
            <div class="mainBox">
              <div class="image-text_6">
                <div class="text-group_11">
                  <span class="text_8">{{ item.plate_number }}</span>
                </div>
              </div>
              <div class="text-group_12" @click="deleteBtn(item)">
                <!-- <van-button size="mini" type="danger" @click="deleteBtn(item)">
                删除
              </van-button> -->
                <!-- <div>
                <img src="../../../assets/img/deleteimg@2x.png" alt="">
              </div> -->
                <van-icon class="iconfont icon-shanchu"/>
                <span class="delText">删除</span>
              </div>
            </div>
            <div class="lineline" v-if="myCarList.length - 1 !== index"></div>
          </div>
        </div>
        <van-empty v-if="myCarList.length===0" description="你还没有添加车辆，现在就去添加吧" />
      </div>

      <div class="text-wrapper_1">
        <van-button class="pay_button" type="info" color="#4797EA" @click="add"
          >添加车辆</van-button
        >
      </div>
    </main>
  </div>
  </div>
</template>
<script>
import axios from 'axios'
import * as common from '../../../api/common'
import * as constant from '../../../api/constant'
import { Dialog } from 'vant'
import { mapState } from 'vuex'
export default {
  data () {
    return {
      show: false,
      isPayment: true, // 是否是缴费记录 默认不是
      myCarList: [
      ],
      isError: false
    }
  },
  mounted () {
    this.doQuery()
  },
  computed: {
    ...mapState({
      sid: state => state.sid.sid,
      channelPath: state => state.channelPath.channelPath,
      openid: state => state.openID.openID
    })

  },
  methods: {
    async doQuery () {
      this.$toast.loading({
        forbidClick: true,
        message: '请稍候...'
        // duration: 10000
      })
      console.log('查询我的车辆ing')
      try {
        const { data } = await axios({
          url: common.cloudPayUrl + '/pub/pay.bill.park_plate.get_bind_park_vh',
          method: 'POST',
          data: {
            sid: this.sid,
            openid: this.openid
          },
          header: {
            'content-type': 'application/json' // 默认值
          }
        })
        if (data.code === common.resCode.OK) {
          this.myCarList = data.list

          // 保存到vuex
        } else {
          this.myCarList = []
        }
        this.$store.commit(constant.keyMyCarListVuex, this.myCarList)

        this.$toast.clear()
      } catch (e) {
        console.log('ERRor', e)
        this.$toast.clear()
        // this.myCarList = [
        //   {
        //     id: 0,
        //     plateNum: '浙A00001'
        //   },
        //   {
        //     id: 1,
        //     plateNum: '浙A00002'
        //   },
        //   {
        //     id: 3,
        //     plateNum: '浙A00003'
        //   }
        // ]
        // this.$store.commit(constant.keyMyCarListVuex, this.myCarList)
      }
    },
    deleteBtn (row) {
      console.log(row)
      Dialog.confirm({
        title: '提示',
        message: '是否要删除车辆' + row.plate_number
      })
        .then(() => {
          // on confirm
          this.removeVh(row)
        })
        .catch(() => {
          // on cancel
        })
    },
    async removeVh (item) {
      try {
        const { res } = await axios({
          url: common.cloudPayUrl + '/pub/pay.bill.park_plate.remove_park_vh',
          method: 'POST',
          data: {
            sid: this.sid,
            openid: this.openid,
            plate_number: item.plate_number
          },
          header: {
            'content-type': 'application/json' // 默认值
          }
        })
        // todo
        console.log('删除车辆', res)
        if (res.code === common.resCode.OK) {
          this.$toast('删除成功')
          this.doQuery()
        } else {
          this.$toast('删除失败', res.msg)
        }
      } catch (e) {
        console.log('ERROR', e)
      }
    },
    add () {
      this.$router.push('/myCarAdd')
    },
    onClickLeft () {
      // this.$router.go(-1)
      this.$router.replace({ path: '/index', query: { sid: this.sid, channel_path: this.channelPath } })
    }

  }
}
</script>
<style lang="scss" scoped>
.page {
}
main {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.section_2 {
  background-color: rgba(255, 255, 255, 1);
  width: 3.43rem;
  align-self: center;
  margin-top: 0.13rem;
  display: flex;
  flex-direction: column;

  padding: 0.08rem 0.01rem 0.11rem 0;
  .itemBox {
    width: 3.25rem;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    margin: 0.04rem 0.07rem 0 0.1rem;
    .mainBox {
      width: 3.25rem;
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      /* margin: 8rpx 14rpx 0 20rpx; */
      .image-text_6 {
        width: 1.29rem;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        .text-group_11 {
          display: flex;
          flex-direction: column;
          .text_8 {
            overflow-wrap: break-word;
            color: #333;
            font-size: 0.14rem;
            font-family: PingFangSC-Regular;
            font-weight: normal;
            text-align: left;
            white-space: nowrap;
            line-height: 0.22rem;
            margin-right: 0.39rem;
          }
        }
      }
      .text-group_12 {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.12rem;
        color: #dc5656;

        .delText {
          margin-left: 0.04rem;
          font-size: 0.12rem;
          line-height: 0.2rem;
        }
      }
    }
    .lineline {
      height: 0.01rem;
      background-color: #e2e2e2;
      margin-top: 0.07rem;
      margin-bottom: 0.07rem;
    }
  }
}
.text-wrapper_1 {
  // margin: -0.01rem 0.07rem 0 0.15rem;
  // margin-bottom: 0.16rem;
  .pay_button {
    // background-color: #4797ea;
    width: 90%;
  }
}
</style>
